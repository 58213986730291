import React from "react";
import ReactDOM from "react-dom";
import ResetPasswordPage from "main_pages/ResetPasswordPage";
import ForgotPasswordPage from "main_pages/ForgotPasswordPage/index";
import RegisterPage from "main_pages/RegisterPageV1";
import LoginPage from "main_pages/LoginPage";
import { PermanentLinkType } from "common/ModulesApi";
import ErrorBoundary from "common/ErrorBoundary";
import Auth0ProviderWrapper from "Auth0ProviderWrapper";
import referralLinkIsValid from "./RegisterPageV1/referralLinkValidation";

export default function renderSharedPages(auth0Props) {
    let pathName = window.location.pathname;

    if (pathName === "/shared_module.html") {
        const urlParams = new URLSearchParams(window.location.search);
        let sessionId = urlParams.get("session_id");
        let moduleId = urlParams.get("shared_module_id");
        if (moduleId == null) return true;
        moduleId = Number(moduleId);
        let enterPoint = (
            <ErrorBoundary>
                <Auth0ProviderWrapper
                    redirectUri={window.location.href}
                    modulePath={"main_pages/SharedKitPage"}
                    moduleProps={{
                        moduleId: moduleId,
                        sessionId: sessionId,
                    }}
                    {...auth0Props}
                ></Auth0ProviderWrapper>
            </ErrorBoundary>
        );

        ReactDOM.render(enterPoint, document.getElementById("root"));
        return true;
    }
    if (
        pathName === "/shared_auth_canvas_module.html"
    ) {
        let enterPoint = (
            <ErrorBoundary>
                <Auth0ProviderWrapper
                    redirectUri={window.location.href}
                    modulePath={"main_pages/CanvasModuleSharedPage"}
                    moduleProps={{
                        requireAuth: true,
                    }}
                    {...auth0Props}
                ></Auth0ProviderWrapper>
            </ErrorBoundary>
        );

        ReactDOM.render(enterPoint, document.getElementById("root"));
        return true;
    }
    if (
        pathName.startsWith("/app/")
    ) {
        const after = {
            pathname: window.location.pathname,
            search: decodeURIComponent(window.location.search),
        };
        let afterString = JSON.stringify(after);
        let redirectUriWithOrigin = `${
            window.location.origin
        }/redirect.html?after=${encodeURIComponent(afterString)}`;
        let enterPoint = (
            <ErrorBoundary>
                <Auth0ProviderWrapper
                    redirectUri={redirectUriWithOrigin}
                    modulePath={"main_pages/CanvasModuleSharedPage"}
                    moduleProps={{
                        requireAuth: true,
                    }}
                    {...auth0Props}
                ></Auth0ProviderWrapper>
            </ErrorBoundary>
        );

        ReactDOM.render(enterPoint, document.getElementById("root"));
        return true;
    }
    if (pathName === "/tutorial.html") {
        const CanvasModuleSharedPage =
            require("main_pages/CanvasModuleSharedPage").default;

        let enterPoint = (
            <ErrorBoundary>
                <CanvasModuleSharedPage requireAuth={false} isTutorial />
            </ErrorBoundary>
        );

        ReactDOM.render(enterPoint, document.getElementById("root"));
        return true;
    }
    if (pathName === "/shared_canvas_module.html") {
        const CanvasModuleSharedPage =
            require("main_pages/CanvasModuleSharedPage").default;

        let enterPoint = (
            <ErrorBoundary>
                <CanvasModuleSharedPage requireAuth={false} />
            </ErrorBoundary>
        );

        ReactDOM.render(enterPoint, document.getElementById("root"));
        return true;
    }
    if (pathName === "/reset_password.html") {
        ReactDOM.render(
            <ErrorBoundary>
                <ResetPasswordPage />
            </ErrorBoundary>,
            document.getElementById("root")
        );
        return true;
    }
    if (pathName === "/register.html") {
        const urlParams = new URLSearchParams(window.location.search);
        let linkId = null;
        let permanentLinkType;

        const linkTypes = {
            "id": undefined,
            "a": PermanentLinkType.App,
            "m": PermanentLinkType.Module,
            "r": PermanentLinkType.Referral,
            "s": PermanentLinkType.Vanilla
        };

        for (let key in linkTypes) {
            linkId = urlParams.get(key);
            if (linkId != null) {
                permanentLinkType = linkTypes[key];
                break;
            }
        }

        // If no linkId is found, then it's a vanilla registration
        if (linkId == null) {
            linkId = urlParams.get("s");
            permanentLinkType = PermanentLinkType.Vanilla;
        }

        if (permanentLinkType === PermanentLinkType.Referral &&
            referralLinkIsValid(linkId) === false) {
            // window.location.href = "/404.html";
            window.location.href = "register.html";
        }

        ReactDOM.render(
            <ErrorBoundary>
                <RegisterPage
                    linkId={linkId}
                    permanentLinkType={permanentLinkType}
                />
            </ErrorBoundary>,
            document.getElementById("root")
        );
        return true;
    }
    if (pathName === "/forgot_password.html") {
        ReactDOM.render(
            <ErrorBoundary>
                <ForgotPasswordPage />
            </ErrorBoundary>,
            document.getElementById("root")
        );
        return true;
    }
    return false;
}
